const ReaderImg = () => {
	return (
		<svg
			id="8d45fc85-8f50-4d13-8ede-4efe67a9dde5"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			width="404.4375"
			height="292.635"
			className="m-auto block"
			viewBox="0 0 1078.5 780.36"
		>
			<defs>
				<linearGradient
					id="cb20406f-0184-40ae-b4eb-eddd07e14298"
					x1="600"
					y1="724.97"
					x2="600"
					y2="197.23"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopColor="gray" stopOpacity="0.25" />
					<stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
					<stop offset="1" stopColor="gray" stopOpacity="0.1" />
				</linearGradient>
				<linearGradient
					id="bd5348f2-d6e7-4b10-83c1-a84cbaf770d6"
					x1="906.56"
					y1="809.07"
					x2="906.56"
					y2="164.43"
					gradientTransform="matrix(-1, 0, 0, 1, 1767, 0)"
					xlinkHref="#cb20406f-0184-40ae-b4eb-eddd07e14298"
				/>
			</defs>
			<title>Bibliophile</title>
			<path
				d="M991.68,507.48C957.58,563.25,963.61,639,978.22,698.73c4.89,20,10.54,41.09,6.19,63.5-5.32,27.4-24.38,49-43.63,61.36-35.1,22.55-75.06,22.09-104-1.19-25.05-20.12-41.45-55.32-67-74.35-42.81-31.85-101.77-12.18-153.06,18-36.29,21.34-76.23,48.35-109.6,33.3-23.48-10.59-38-40.69-44.73-73.49-3.24-15.83-5.16-33.13-12.69-45.18-4.48-7.16-10.66-11.94-17.07-15.85-58.51-35.67-138-5.3-194.39-45.73-38.09-27.31-59.58-83.94-66.45-144.57S171,348.95,178.58,285c5.42-45.44,12.7-94.1,36.34-131.15,25-39.19,64.24-56.81,98.26-53.3s63.48,25.34,88.84,52c31.7,33.32,60.22,75.93,101.38,86.54,28,7.23,58.72-1.41,88.55-6.35,49.87-8.26,99-6.16,147.76-3.35,46.69,2.69,93.67,6.11,137.12,22.63,30.75,11.69,54.28,36.2,82.95,52.08,18.69,10.35,39.82,11.58,57.22,25.42,21.43,17,39.9,48.6,31.12,88.61C1039.77,456.18,1009.77,477.89,991.68,507.48Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
				opacity="0.1"
			/>
			<path
				d="M355.05,337.12s-147.14-32.9,40.08-225.39c0,0,80.7,76.49,51.1,171.57-11.9,38.23-50.2,61.83-89,54.27Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M355.05,337.12c-3.23-.72-144-36.15,40.08-225.39,0,0,80.7,76.49,51.1,171.57-11.9,38.23-50.2,61.83-89,54.27Z"
				transform="translate(-60.75 -59.82)"
				fill="#fff"
				opacity="0.4"
			/>
			<path
				d="M357,338.22s77.72-117.08,38.15-226.48"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#535461"
				strokeMiterlimit="10"
			/>
			<path
				d="M960.36,450.81S935,348.7,973.43,276c16.14-30.51,22.55-65.27,17.16-99.36a268.09,268.09,0,0,0-12.14-47.5"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#535461"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="M1002.4,83.18c-.53,12.36-24.44,47.23-24.44,47.23s-20.84-36.79-20.31-49.15a22.4,22.4,0,0,1,44.75,1.92Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1036.46,153.21c-7.16,10.09-46.19,26.37-46.19,26.37s2.5-42.21,9.66-52.3a22.4,22.4,0,1,1,36.52,25.93Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1029.83,270.11c-11.27,5.09-53.18-.55-53.18-.55s23.47-35.17,34.74-40.27a22.4,22.4,0,0,1,18.44,40.82Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1004.9,357.43c-10.25,6.92-52.51,8.44-52.51,8.44s17.19-38.63,27.44-45.56a22.4,22.4,0,0,1,25.07,37.12Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M943.19,180c8.49,9,49.41,19.68,49.41,19.68s-8.36-41.45-16.85-50.44A22.4,22.4,0,0,0,943.19,180Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M915.33,282.45c10.8,6,53,4,53,4S948,249.39,937.19,243.36a22.4,22.4,0,0,0-21.86,39.09Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M901.92,385.78c9.62,7.77,51.59,12.9,51.59,12.9s-13.82-40-23.44-47.74a22.4,22.4,0,0,0-28.15,34.84Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1002.4,83.18c-.53,12.36-24.44,47.23-24.44,47.23s-20.84-36.79-20.31-49.15a22.4,22.4,0,0,1,44.75,1.92Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M1036.46,153.21c-7.16,10.09-46.19,26.37-46.19,26.37s2.5-42.21,9.66-52.3a22.4,22.4,0,1,1,36.52,25.93Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M1029.83,270.11c-11.27,5.09-53.18-.55-53.18-.55s23.47-35.17,34.74-40.27a22.4,22.4,0,0,1,18.44,40.82Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M1004.9,357.43c-10.25,6.92-52.51,8.44-52.51,8.44s17.19-38.63,27.44-45.56a22.4,22.4,0,0,1,25.07,37.12Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M943.19,180c8.49,9,49.41,19.68,49.41,19.68s-8.36-41.45-16.85-50.44A22.4,22.4,0,0,0,943.19,180Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M915.33,282.45c10.8,6,53,4,53,4S948,249.39,937.19,243.36a22.4,22.4,0,0,0-21.86,39.09Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M901.92,385.78c9.62,7.77,51.59,12.9,51.59,12.9s-13.82-40-23.44-47.74a22.4,22.4,0,0,0-28.15,34.84Z"
				transform="translate(-60.75 -59.82)"
				fill="#fc6681"
			/>
			<path
				d="M867.52,384.86s106.36-89.13,130-134.08,53.77-83.67,53.77-83.67"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#535461"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="M1037.34,267.57l-46-10.38S1002.18,305.73,1037.34,267.57Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M967,218l26.48,39.66S941.74,261.42,967,218Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1055.44,230.29l-42.06-7.38S1039.81,263.1,1055.44,230.29Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M993.24,194.86,1014,222S977.25,225.46,993.24,194.86Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1035.14,189.45s35.85,1.71,40,5.13-5.34,23.48-19,20.21S1035.14,189.45,1035.14,189.45Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M1020.82,157.44s14.63,26.66,14.08,31.73-22.29,10.77-26.69-8.45S1020.82,157.44,1020.82,157.44Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<circle
				cx="1059.3"
				cy="171.73"
				r="10"
				transform="translate(515.02 1069.93) rotate(-72.43)"
				fill="#ffd037"
			/>
			<circle
				cx="1043.65"
				cy="161.53"
				r="10"
				transform="translate(513.82 1047.89) rotate(-72.43)"
				fill="#ffd037"
			/>
			<path
				d="M389.61,407.74s43-32-11-144c0,0-72-89,0-173,0,0-157,4-154,150s78.5,167,78.5,167Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M349.45,407.74s-10.83-77-39.83-124A100.81,100.81,0,0,1,297.43,253c-9.69-42.63,4.13-87.21,35.44-117.72l45.75-44.57"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#535461"
				strokeMiterlimit="10"
				strokeWidth="2"
			/>
			<path
				d="M479.16,150.69s9.88,12.92-4.56,32.42-26.34,36-21.53,48.12c0,0,21.78-36.22,39.51-36.73S498.66,172.47,479.16,150.69Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M479.16,150.69a16,16,0,0,1,2,4.05c17.3,20.32,26.51,39.29,9.89,39.77-15.49.44-34.07,28.14-38.52,35.13a15,15,0,0,0,.53,1.59s21.78-36.22,39.51-36.73S498.66,172.47,479.16,150.69Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M497.52,167.15c0,4.55-.51,8.23-1.14,8.23s-1.14-3.69-1.14-8.23.64-2.41,1.27-2.41S497.52,162.61,497.52,167.15Z"
				transform="translate(-60.75 -59.82)"
				fill="#ffd037"
			/>
			<path
				d="M503.82,172.58c-4,2.18-7.47,3.49-7.77,2.94s2.69-2.76,6.68-4.94,2.42-.59,2.72,0S507.81,170.4,503.82,172.58Z"
				transform="translate(-60.75 -59.82)"
				fill="#ffd037"
			/>
			<path
				d="M427,150.69s-9.88,12.92,4.56,32.42,26.34,36,21.53,48.12c0,0-21.78-36.22-39.51-36.73S407.48,172.47,427,150.69Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M427,150.69a16,16,0,0,0-2,4.05c-17.3,20.32-26.51,39.29-9.89,39.77,15.49.44,34.07,28.14,38.52,35.13a15,15,0,0,1-.53,1.59S431.29,195,413.56,194.51,407.48,172.47,427,150.69Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M408.62,167.15c0,4.55.51,8.23,1.14,8.23s1.14-3.69,1.14-8.23-.64-2.41-1.27-2.41S408.62,162.61,408.62,167.15Z"
				transform="translate(-60.75 -59.82)"
				fill="#ffd037"
			/>
			<path
				d="M402.32,172.58c4,2.18,7.47,3.49,7.77,2.94s-2.69-2.76-6.68-4.94-2.42-.59-2.72,0S398.33,170.4,402.32,172.58Z"
				transform="translate(-60.75 -59.82)"
				fill="#ffd037"
			/>
			<path
				d="M1139.25,705.61l-87.61-385.48-32.55,0c-1.3-14.73-2.17-29-2.5-42.44,0,0-118,25.82-212.1-48.88s-204.73,6.46-204.73,6.46l-.43-.31,0-.15V235c-7.68-5.44-113.66-78.07-204.27-6.13-94.06,74.7-212.1,48.88-212.1,48.88-.33,13.61-1.21,28-2.54,42.87l-30.2,0L60.75,707s363.7,13.62,491.15,1.06c4.57,9.66,24.27,16.92,47.87,16.92,26.52,0,48.09-9.17,48.84-20.6C747.82,711.93,961.3,724,1139.25,705.61Z"
				transform="translate(-60.75 -59.82)"
				fill="url(#cb20406f-0184-40ae-b4eb-eddd07e14298)"
			/>
			<path
				d="M159.59,323.52,72,701.86s482.17,18.06,521-7.22c0,0,299.33,30.25,535,5.87l-85.78-377.43Z"
				transform="translate(-60.75 -59.82)"
				fill="#e2e2ec"
			/>
			<path
				d="M189.39,336.59,107.73,689.32s449.54,16.84,485.74-6.73c0,0,279.07,28.2,498.79,5.47l-80-351.89Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M1007.91,281.54s-115.58,25.28-207.68-47.86S599.77,240,599.77,240s-108.35-79.46-200.45-6.32-207.68,47.86-207.68,47.86C188,428.72,119.41,667.1,119.41,667.1s126.41,34.31,293.46-35.21,186.91,24.38,186.91,24.38,19.86-93.91,186.91-24.38,293.46,35.21,293.46,35.21S1011.52,428.72,1007.91,281.54Z"
				transform="translate(-60.75 -59.82)"
				fill="#f1f2fb"
			/>
			<path
				d="M376.75,659s152.6-34.31,223,16.25c0,0,67.72-61.4,224.83-13.54"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<ellipse cx="539.02" cy="638.88" rx="47.86" ry="20.77" fill="#e8eaf8" />
			<path
				d="M599.32,239.55s34.31,174.27.9,419.87"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M224.7,336.17s71.87,16.7,134.08,0,176.43-71.33,214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M224.7,366.87s71.87,16.7,134.08,0,196.91-15.8,214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M224.7,421s71.87,16.7,134.08,0,176.43-71.33,214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M224.7,451.74s71.87,16.7,134.08,0,196.91-15.8,214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M974.85,336.17s-71.87,16.7-134.08,0-176.43-71.33-214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M974.85,366.87s-71.87,16.7-134.08,0-196.91-15.8-214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M974.85,421s-71.87,16.7-134.08,0-176.43-71.33-214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M974.85,451.74s-71.87,16.7-134.08,0-196.91-15.8-214,0"
				transform="translate(-60.75 -59.82)"
				fill="none"
				stroke="#000"
				strokeMiterlimit="10"
				strokeWidth="4"
				opacity="0.1"
			/>
			<path
				d="M976.31,776.79c3-2.29,5.85-5,6.72-8.27a7,7,0,0,0-4.69-8.42c-4.31-1.34-8.91,1.09-12.41,3.55s-7.49,5.27-12.06,4.75c4.73-3.42,7-9,5.68-14a5.43,5.43,0,0,0-1.58-2.85c-2.39-2.09-6.73-1.19-9.59.45-9.11,5.23-11.65,15.32-11.7,24.41-.92-3.28-.14-6.69-.17-10.06s-1.15-7.09-4.63-8.9a16.55,16.55,0,0,0-7.06-1.35c-4.09-.12-8.66.21-11.45,2.66-3.47,3-2.57,8.13.45,11.47s7.62,5.44,11.85,7.75c3.23,1.76,6.49,3.81,8.47,6.59a6,6,0,0,1,.63,1.18h25.67A73.8,73.8,0,0,0,976.31,776.79Z"
				transform="translate(-60.75 -59.82)"
				fill="#52b788"
			/>
			<path
				d="M798.82,416.69s.25,1.44.67,4c-.22.4-.44.8-.67,1.19-8.2,13.8-9.46,33.68-8.69,50A163.6,163.6,0,0,0,797,510.68a181.36,181.36,0,0,1,7,50.4,36.64,36.64,0,0,0,11.48,8.36c.22.67.45,1.35.65,2a204.43,204.43,0,0,1,7.63,38.61l2.58,32.66s19.77,73.92,25.78,87.67c4.28,9.78,3.34,30,2.44,40.84-2.57,7.29-5.7,14.24-8.46,15-5,1.42-10,13.46-2.72,19.51h0a11.6,11.6,0,0,0,1.75,1.2l.29.16.53.27.78.35.4.15.83.28.36.11c.39.11.79.22,1.22.32a21.83,21.83,0,0,0,14.84-2.35c5.48-.82,10.35-3.76,14-6.73l-.1,5.21h6L884,794.29c1.08-1.21,1.68-2,1.68-2s.18-6.29.87-13.61l-.44,23.49h6l-1.62-43.35a6.92,6.92,0,0,1,1.19-1.77c5-5-3.64-21.13-8.11-23.82,2-10.6,9.63-25.91,18.43-55.26,12.89-43-3.44-85.09-3.44-85.09v-33c4.33-1.86,6.88-3.13,6.88-3.13s-6-35.24,0-60.16c3.89-16.11,4.9-30.42,1.65-44.11,4.32-14.88,7.81-26.37,7.81-26.37l6-61.88c1.53-9.29,2.56-17.24,3.22-24,.11-.84.19-1.69.23-2.53,1.1-12.59.86-21,.14-26.5a42.1,42.1,0,0,0,.36-7.61,30.05,30.05,0,0,0,.16-3.93c.86-1,1.7-2,2.5-3a43.09,43.09,0,0,0,5.39-8.6c6.61-12.47,6.8-27.7-2.43-38.69-5.68-6.77-7.45-14.06-12.36-21.27-4.49-6.6-5.34-14.51-6.42-22.11a270.73,270.73,0,0,0-5.5-28.07c-.69-2.72-4.47-4.39-6.35-6.65-3.63-4.37-14.23-4.22-20.34-5.19-10.26-1.63-21.37-3.15-30.71.88-6.5,2.81-11.17,7.92-16.25,12.43-5.47,4.85-16.67,7.19-18.41,13-1.55,2.22-1.63,5,1,8.72,2.54,3.58,6.54,6.14,10.27,8.82.48.34.93.7,1.4,1a36.52,36.52,0,0,0,19.85,38.64c-.1.65-.21,1.29-.33,1.92A115.82,115.82,0,0,1,841.8,268c-1.11,3.32-13.95,9.12-23,12.84a11.5,11.5,0,0,0-3.67-2.53c-6.88-1.72-30.08,11.17-31.8,15.47-.48,1.2.78,4.52,2.83,8.7a31,31,0,0,0,.93,5.64c-.51,8.8.41,23,4.84,45.83Zm96.5,9.67c-.32-.61-.65-1.23-1-1.87-9.55-19.31-15.22-50.53-1.54-69.15,1.22.13,2.44.25,3.66.33.81,25.34.34,67,.34,67S896.24,424.07,895.32,426.36ZM861.23,573.54c1.94,9.75,4.22,21.08,6.35,31.38a82.52,82.52,0,0,1-.5,36.32L859,617l-1.88-42.65L857,572.6q1.95-.33,3.87-.71Z"
				transform="translate(-60.75 -59.82)"
				fill="url(#bd5348f2-d6e7-4b10-83c1-a84cbaf770d6)"
			/>
			<path
				d="M867.47,603.06a81.22,81.22,0,0,1-.49,35.75,46.25,46.25,0,0,1-2,6.55l2.28,92.7.26,10.51a52.9,52.9,0,0,0,6.4.94,29.07,29.07,0,0,0,6,0c2-.25,3.76-.85,4.7-2s1.14-3-.19-5.69a16.92,16.92,0,0,1-1.63-9.55c.08-.92.23-1.89.41-2.89,2-10.43,9.47-25.51,18.14-54.39,12.69-42.3-3.38-83.75-3.38-83.75V552.3l-41.45-4.23s2,10.48,4.75,24.1C863.13,581.77,865.37,592.92,867.47,603.06Z"
				transform="translate(-60.75 -59.82)"
				fill="#fdc2cc"
			/>
			<path
				d="M867.47,603.06a81.22,81.22,0,0,1-.49,35.75,46.25,46.25,0,0,1-2,6.55l2.28,92.7.26,10.51a52.9,52.9,0,0,0,6.4.94,29.07,29.07,0,0,0,6,0c2-.25,3.76-.85,4.7-2s1.14-3-.19-5.69a16.92,16.92,0,0,1-1.63-9.55c.08-.92.23-1.89.41-2.89,2-10.43,9.47-25.51,18.14-54.39,12.69-42.3-3.38-83.75-3.38-83.75V552.3l-41.45-4.23s2,10.48,4.75,24.1C863.13,581.77,865.37,592.92,867.47,603.06Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M809.94,552.3a127,127,0,0,1,6.87,17.83,201.21,201.21,0,0,1,7.51,38l2.54,32.15s19.46,72.75,25.38,86.29c4.21,9.63,3.29,29.53,2.4,40.22-.36,4.34-.71,7.16-.71,7.16l8.63-10.57,11.31-13.87,5.16-6.33,1.13-1.38a52,52,0,0,1-3.3-8.83s0,0,0,0a150.93,150.93,0,0,1-5.15-28.35c-2.54-26.22-3.38-61.76-3.38-61.76l-1.34-4-8-23.91-1.85-42-.69-15.55Z"
				transform="translate(-60.75 -59.82)"
				fill="#fdc2cc"
			/>
			<path
				d="M875.38,734.79a60.2,60.2,0,0,0-10.06,19.89c-2.69,9.15-7.42,23.21-11.39,24.34-5.92,1.69-11.84,18.61,3.38,22s27.92-13.54,27.92-13.54.85-29.61,5.92-34.68-4.23-22-8.46-23.69C881,728.42,878.14,731.22,875.38,734.79Z"
				transform="translate(-60.75 -59.82)"
				fill="#5f5d7e"
			/>
			<path
				d="M852.24,798.48a20.37,20.37,0,0,0,11.88-1.08,26.55,26.55,0,0,0,16.62-22.81c.72-9.06,2.24-21.24,5.33-24.33,4-4-1-15.4-5.3-20.85a2.29,2.29,0,0,1,1.91-.3c4.23,1.69,13.54,18.61,8.46,23.69s-5.92,34.68-5.92,34.68S872.55,804.4,857.32,801a14,14,0,0,1-6.06-2.79C851.58,798.31,851.9,798.4,852.24,798.48Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M889.95,753.51l1.63,43.7h-5.92l.82-43.67a1.68,1.68,0,0,1,1.74-1.59h0A1.68,1.68,0,0,1,889.95,753.51Z"
				transform="translate(-60.75 -59.82)"
				fill="#5f5d7e"
			/>
			<path
				d="M889.95,753.51l1.63,43.7h-5.92l.82-43.67a1.68,1.68,0,0,1,1.74-1.59h0A1.68,1.68,0,0,1,889.95,753.51Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.15"
			/>
			<path
				d="M867.77,737.33a60.2,60.2,0,0,0-10.06,19.89c-2.69,9.15-7.42,23.21-11.39,24.34-5.92,1.69-11.84,18.61,3.38,22S877.62,790,877.62,790s.85-29.61,5.92-34.68-4.23-22-8.46-23.69C873.37,731,870.53,733.76,867.77,737.33Z"
				transform="translate(-60.75 -59.82)"
				fill="#5f5d7e"
			/>
			<path
				d="M844.63,801a20.37,20.37,0,0,0,11.88-1.08,26.55,26.55,0,0,0,16.62-22.81c.72-9.06,2.24-21.24,5.33-24.33,4-4-1-15.4-5.3-20.85a2.29,2.29,0,0,1,1.91-.3c4.23,1.69,13.54,18.61,8.46,23.69S877.62,790,877.62,790s-12.69,16.92-27.92,13.54a14,14,0,0,1-6.06-2.79C844,800.85,844.29,800.94,844.63,801Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M882.33,756l1.63,43.7H878l.82-43.67a1.68,1.68,0,0,1,1.74-1.59h0A1.68,1.68,0,0,1,882.33,756Z"
				transform="translate(-60.75 -59.82)"
				fill="#5f5d7e"
			/>
			<path
				d="M882.33,756l1.63,43.7H878l.82-43.67a1.68,1.68,0,0,1,1.74-1.59h0A1.68,1.68,0,0,1,882.33,756Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.15"
			/>
			<path
				d="M810.79,287.52l33.84,53.3,39.76,5.08s24.53-48.22,23.69-50.76-13.54-7.61-25.38-16.92S885.24,230,885.24,230l-38.91,3.38c.15.52.3,1,.41,1.58,1.55,6.57,1,13.86-.19,20.27a114,114,0,0,1-4.46,16.22C840.4,276.52,810.79,287.52,810.79,287.52Z"
				transform="translate(-60.75 -59.82)"
				fill="#fdc2cc"
			/>
			<path
				d="M856.47,548.07s2,10.48,4.75,24.1a194.13,194.13,0,0,0,36.71-11.72V552.3Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M809.94,552.3a127,127,0,0,1,6.87,17.83c11.77,5.31,26.41,5.16,40.34,2.79l-.69-15.55Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M799.79,422.87c-8.07,13.59-9.31,33.14-8.55,49.26A161,161,0,0,0,798,510.31a178.5,178.5,0,0,1,6.9,49.61c28.76,31.3,99.82-4.23,99.82-4.23s-5.92-34.68,0-59.22c4.12-17.06,5-32.09.82-46.49a73.28,73.28,0,0,0-8.43-18.65c-1.12-1.78-2.21-3.75-3.26-5.86-9.8-19.82-15.46-52.38.3-70.34a32.86,32.86,0,0,1,5.5-5c13.74-9.95,20-21,22.64-30.35a40.81,40.81,0,0,0,1-17.87c-1.69-9.31-25.38-17.77-25.38-17.77.85,27.92-34.68,51.6-34.68,51.6s-40.61-52.45-47.37-54.14-29.61,11-31.3,15.23,18.61,35.53,18.61,35.53S815.87,395.8,799.79,422.87Z"
				transform="translate(-60.75 -59.82)"
				fill="#d39999"
			/>
			<path
				d="M899.62,350.12c13.74-9.95,20-21,22.64-30.35-.66-14-4-16.17-4-16.17S890.31,330.66,892.85,340c.58,2.15,1,7.77,1.27,15.16A32.86,32.86,0,0,1,899.62,350.12Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M852.24,543c5.92,17.77,35.53-13.54,36.38-27.07.54-8.66,10.09-42.61,16.89-65.94a73.28,73.28,0,0,0-8.43-18.65c-1.12-1.78-2.21-3.75-3.26-5.86-3.21,7.89-16.1,40.23-15.35,49.85.85,11-5.08,27.07-5.08,27.07S846.32,525.23,852.24,543Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M919.92,303.59s9.31,5.92,0,62.6L914,427.1s-22.84,75.29-23.69,88.83S859.86,560.76,853.93,543s21.15-40.61,21.15-40.61,5.92-16.07,5.08-27.07,16.07-51.6,16.07-51.6.85-74.44-1.69-83.75S919.92,303.59,919.92,303.59Z"
				transform="translate(-60.75 -59.82)"
				fill="#fdc2cc"
			/>
			<path
				d="M794.72,296.82s-11,2.54,0,59.22l6.77,61.76s15.23,88.83,14.38,99,33,36.38,35.53,22-16.92-38.07-16.92-38.07S826,482.09,826,447.4l-1.69-27.92L812.48,318.82Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M793,296.82s-11,2.54,0,59.22l6.77,61.76s15.23,88.83,14.38,99,33,36.38,35.53,22-16.92-38.07-16.92-38.07-8.46-18.61-8.46-53.3l-1.69-27.92L810.79,318.82Z"
				transform="translate(-60.75 -59.82)"
				fill="#fdc2cc"
			/>
			<path
				d="M787.53,299.78s-5.08,27.07,30.45,36.38S813.75,304,813.75,304,800.22,283.71,787.53,299.78Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M788.37,298.94S783.3,326,818.83,335.31s-4.23-32.15-4.23-32.15S801.06,282.86,788.37,298.94Z"
				transform="translate(-60.75 -59.82)"
				fill="#d39999"
			/>
			<path
				d="M923.73,299.78s5.08,27.07-30.45,36.38S897.5,304,897.5,304,911,283.71,923.73,299.78Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M922.88,298.94S928,326,892.43,335.31s4.23-32.15,4.23-32.15S910.19,282.86,922.88,298.94Z"
				transform="translate(-60.75 -59.82)"
				fill="#d39999"
			/>
			<path
				d="M846.55,255.22c4.67,1.36,9.9-9.58,15.42-9.58,5.85,0,11.36,11.71,16.24,9.23a146.62,146.62,0,0,1,6.18-23.19L846.74,235C848.28,241.53,847.74,248.81,846.55,255.22Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<circle cx="802.07" cy="161.29" r="35.95" fill="#fdc2cc" />
			<path
				d="M815.84,205.59c2.5,3.52,6.44,6,10.11,8.68A111.08,111.08,0,0,1,838.77,225a37.9,37.9,0,0,1,7.17,8.88c2.85,5.32,3.23,11.34,3.57,17.2L853,311.61c.23,4,.46,8.09-.56,12a41,41,0,0,1-4.33,9.48c-4.61,8.18-9.35,16.52-16.77,23-2.48,2.15-5.5,4.93-4.19,7.75,1.06,2.27,4.32,3,7.12,3.33,11.75,1.58,24.77,2.93,34.66-2.8,3.73-2.16,6.82-5.26,11-6.6,5.82-1.85,12.24.08,18.44.07,6.58,0,12.86-2.19,19-4.35a6.81,6.81,0,0,0,3.12-1.76,5.21,5.21,0,0,0,.89-2.39c.93-4.91,1.81-10,.29-14.81-1.65-5.21-6-9.72-6.49-15.1-.64-7.48,6.26-13.57,11.22-19.72,10.56-13.09,12.91-31.94,2.07-44.85-5.59-6.66-7.34-13.84-12.17-20.93-4.42-6.49-5.26-14.28-6.32-21.76a266.49,266.49,0,0,0-5.42-27.63c-.67-2.68-4.4-4.32-6.25-6.55-3.58-4.3-14-4.15-20-5.11-10.1-1.6-21-3.1-30.23.87-6.4,2.76-11,7.8-16,12.23C825,192.3,807.82,194.28,815.84,205.59Z"
				transform="translate(-60.75 -59.82)"
				opacity="0.1"
			/>
			<path
				d="M816.69,203.9c2.5,3.52,6.44,6,10.11,8.68a111.08,111.08,0,0,1,12.82,10.75,37.9,37.9,0,0,1,7.17,8.88c2.85,5.32,3.23,11.34,3.57,17.2l3.52,60.51c.23,4,.46,8.09-.56,12a41,41,0,0,1-4.33,9.48c-4.61,8.18-9.35,16.52-16.77,23-2.48,2.15-5.5,4.93-4.19,7.75,1.06,2.27,4.32,3,7.12,3.33,11.75,1.58,24.77,2.93,34.66-2.8,3.73-2.16,6.82-5.26,11-6.6,5.82-1.85,12.24.08,18.44.07,6.58,0,12.86-2.19,19-4.35a6.81,6.81,0,0,0,3.12-1.76,5.21,5.21,0,0,0,.89-2.39c.93-4.91,1.81-10,.29-14.81-1.65-5.21-6-9.72-6.49-15.1-.64-7.48,6.26-13.57,11.22-19.72,10.56-13.09,12.91-31.94,2.07-44.85-5.59-6.66-7.34-13.84-12.17-20.93-4.42-6.49-5.26-14.28-6.32-21.76a266.49,266.49,0,0,0-5.42-27.63c-.67-2.68-4.4-4.32-6.25-6.55-3.58-4.3-14-4.15-20-5.11-10.1-1.6-21-3.1-30.23.87-6.4,2.76-11,7.8-16,12.23C825.8,190.61,808.67,192.59,816.69,203.9Z"
				transform="translate(-60.75 -59.82)"
				fill="#865a61"
			/>
		</svg>
	);
};
export default ReaderImg;
